export default [
  {
    path: '/myperformance',
    name: 'prestasi-saya-KRA',
    component: () => import('@/pages/exec/My-Performance-KRA.vue'),
    meta: {
      // layout: 'full',
      pageTitle: 'My performance',
      // breadcrumb: [
      //   {
      //     text: 'page4',
      //     active: true,
      //   },
      //   {
      //     text: 'page2',
      //     to: { name: 'page2' },
      //   },
      // ],
    },
  },
]
