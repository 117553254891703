<template>
  <router-link
    v-if="route !== ''"
    :to="{ name: `${route}`}"
    class="mb-2 d-flex"
    style="width: 100%; color: black; "
    :class="[{'ranhill-router-link-exact-active active': $route.meta.activePage === route}]"
  >
    <div
      class="hoverActive d-flex align-items-center justify-content-center"
      :class="status? 'nav-icon-width-selection-expanded' : 'nav-icon-width-selection-collapse'"
      style="border-radius: 10px; transition: ease 0.3s; width: 100%; overflow: hidden; overflow: visible; "
      @mouseover="showFloatingLabel = true"
      @mouseleave="showFloatingLabel = false"
    >
      <div
        class="d-flex align-items-center"
        :class="status? 'justify-content-start' : 'justify-content-center'"
        style="width:100%; "
      >
        <slot>
          <!-- <feather-icon
            :icon="icon"
            class="pointer"
            :size="iconSize"
            :style="[{color: `${iconColor}`}, status ? {'margin-left': '5px'}: '']"
          /> -->
          <div
            :class="icon"
            class="pointer p-1"
            style="font-size: 1.3rem;"
            :size="iconSize"
            :style="[status ? {'margin-right': '5px'}: '']"
          />
        </slot>
        <div
          v-if="status"
          id="tooltip-custom"
          style="font-size: 0.95rem;
         "
        ><span
           class=""
           style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"
         >{{ $t(title) }}</span>
          <!-- <span id="tooltiptext">Tooltip text</span> -->
          <!-- <span
            v-if="status"
            id="tooltiptext"
            style=""
          >{{ $t(title) }} </span> -->
          <custom-tooltip
            id="tooltiptext"
            style=""
            :title="$t(title)"
          />
        </div>
        <!-- <span
          v-if="status"
          class="tooltipText"
          style="font-size: 0.95rem; margin-left: 15px; white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;"
        >{{ $t(title) }} </span>
      </div> -->

      </div>
      <transition
        name="fade-bottom"
        mode="out-in"
      >
        <div
          v-if="!status && showFloatingLabel"
          class="text-center"
          style="position: fixed; left: 55px; background: #FF0000; color: white;
              backdrop-filter: blur(20px); padding: 10px; border-radius: 8px; font-size: 0.9rem; z-index: 102;"
        >
          {{ $t(title) }}
        </div>
      </transition>
    </div>
  </router-link>
  <div
    v-else
    :to="{ name: `${route}`}"
    class="mb-2"
    style="width: 100%; color: black;"
    :class="[{'ranhill-router-link-exact-active active': $route.meta.activePage === route}]"
    @click="$emit('click', $event)"
  >
    <div
      class="hoverActive d-flex align-items-center justify-content-center"
      :class="status? 'nav-icon-width-selection-expanded' : 'nav-icon-width-selection-collapse'"
      style="border-radius: 10px; transition: ease 0.3s; width: 100%; overflow: hidden; "
      @mouseover="showFloatingLabel = true"
      @mouseleave="showFloatingLabel = false"
    >
      <div
        class="d-flex align-items-center"
        :class="status? 'justify-content-start' : 'justify-content-center'"
        style="width: 100%; margin-right: 5px; margin-left: 5px; overflow: hidden;"
      >
        <slot>
          <div
            :class="icon"
            class="pointer"
            :size="iconSize"
            :style="[status ? {'margin-left': '10px'}: '']"
          />
        </slot>

        <span
          v-if="status"
          class=""
          style="font-size: 0.8rem; margin-left: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;"
        >{{ title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import CustomTooltip from '@/views/base/side-bar/components/custom-tooltip.vue'

export default {
  components: {
    CustomTooltip,
  },
  directives: {
    Ripple,
  },
  props: {
    status: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Title',
    },
    route: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'BoxIcon',
    },
    iconColor: {
      type: String,
      default: '#4E5B6D',
    },
    iconSize: {
      type: String,
      default: '20',
    },
  },

  data(){
    return {
      showFloatingLabel: null,

    }
  },
  mounted(){

  },
  methods: {

  },
}
</script>
<style scoped>

</style>
