<template>
  <b-dropdown
    :variant="navBarExpanded ? variant : outline"
    size="sm"
    no-caret
    style="color: blue;"
    :split="navBarExpanded"
    :split-variant="outline"
  >
    <template #button-content class="local">
      <img
        v-if="navBarExpanded"
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <div style="width: 20px">
        <span v-if="!navBarExpanded" class="">{{ currentLocale.locale }}</span>
      </div>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="onSelectionLanguage(localeObj)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>
<style scoped>
/* .localizationDropdown {
  background-color: white !important;
  background: white !important;
} */
.local :hover{
background-color: white !important;
}
.local{
background-color: white !important;
}
</style>

<script>
import { BDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
// import vSelect from 'vue-select'
import { localize } from "vee-validate";

export default {
  components: {
    // vSelect,
    // eslint-disable-next-line vue/no-unused-components
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BImg,
  },
  props: {
    navBarExpanded: {
      type: Boolean,
      required: false,
      default: true
    },
    outline: {
      type: String,
      required: false,
      default: 'outline-primary'
    },
    variant: {
      type: String,
      required: false,
      default: 'primary'
    }
  },
  data() {
    return {
      locale: this.$store.getters.getAppLanguage,
    };
  },
  computed: {
    currentLocale() {
      this.loadLocale(this.$i18n.locale);
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
    ...mapGetters(["getAppLanguage"]),
  },
  methods: {
    ...mapActions(["setAppLanguage"]),
    onSelectionLanguage(localeObj) {
      this.locale = localeObj.locale;
      this.$i18n.locale = localeObj.locale;

      this.setAppLanguage(localeObj.locale);
    },

    loadLocale(code) {
      if (code === "en") {
        return import(`vee-validate/dist/locale/${code}.json`).then(
          (locale) => {
            localize(code, locale);
          }
        );
      } else {
        return import(`vee-validate/dist/locale/ms_MY.json`).then((locale) => {
          localize(code, locale);
        });
      }
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "en",
        img: require("@/assets/images/flags/uk.png"),
        name: "English",
      },
      {
        locale: "ma",
        img: require("@/assets/images/flags/my.png"),
        name: "Malay",
      },
    ];
    /* eslint-disable global-require */

    return {
      locales,
    };
  },
};
</script>

