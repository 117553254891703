export default [
  // {
  //   path: '/error-404',
  //   name: 'error-404',
  //   component: () => import('@/views/other/miscellaneous/Error404.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '*',
  //   redirect: 'error-404',
  // },

  // AUTH START
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/other/auth/Login.vue'),
    // component: () => import('@/views/other/miscellaneous/UnderMaintenance.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layout: 'full',
      pageTitle: 'Login',
      auth: false,
    },
  },

  {
    path: '/forgotpassword',
    name: 'forgot-password',
    component: () => import('@/views/other/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Login',
      auth: false,
    },
  },

  {
    path: '/resetpassword',
    name: 'reset-password',
    component: () => import('@/views/other/auth/ResetPassword.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Reset Password',
      auth: false,
    },
  },

  {
    path: '/authpassword',
    name: 'auth-password',
    component: () => import('@/views/other/auth/AuthPassword.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Auth Password',
      auth: false,
    },
  },

  // AUTH ENDED
  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   // component: () => import('@/views/hr/dashboard/Dashboard.vue'),
  //   component: () => import('@/views/other/miscellaneous/ComingSoon.vue'),
  //   // component: () => import('@/pages/Dashboard.vue'),
  //   meta: {
  //     pageTitle: 'Dashboard',
  //     breadcrumb: [
  //       {
  //         text: 'Dashboard',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
