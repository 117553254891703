import Vue from 'vue'
import Vuex from 'vuex'
import SecureLS from 'secure-ls'
import { createVuexPersistedState } from "vue-persistedstate"

// const SecureLocalStorage = new SecureLS({ encodingType: 'aes' })
const ls = new SecureLS({
  encodingType: 'aes',
  isCompression: false,
  encryptionSecret: 's3cr3tPa$$w0rd@123',
})

Vue.use(Vuex)

const getDefaultState = () => ({
  userDetails: {},
  userSetting: {},
  userPerformance: {},
  roles: {
    default: [],
    selectedRole: '',
    isAdmin: false,
    isUser: false,
    isExecutive: false,
    isTopManagement: false,
    isHR: false,
    isHOD: false,
  },
  base: {
    fontSize: 16,
  },
  sajRanhillToken: null,
  appLanguage: localStorage.getItem("appLanguage") === undefined || localStorage.getItem("appLanguage") === 'undefined' ? 'ma' : localStorage.getItem("appLanguage"),
})

const initialState = getDefaultState()

export default new Vuex.Store({
  state: {
    ...initialState,
    appLanguage: 'ma',
  },
  getters: {
    sajRanhillToken: state => state.sajRanhillToken,
    rolesOriginal: state => state.roles,
    roles: state => ({
      default: state.roles.default,
      selectedRole: state.roles.selectedRole,
      // isAdmin: state.roles.selectedRole === 'Admin',
      // isUser: state.roles.selectedRole === 'User',
      // isExecutive: state.roles.selectedRole === 'Executive',
      // isTopManagement: state.roles.selectedRole === 'Top Management',
      // isHR: state.roles.selectedRole === 'Human Resource',
      // isHOD: state.roles.selectedRole === 'User',
      isAdmin: state.roles.selectedRole === 1,
      isUser: state.roles.selectedRole === 2,
      isExecutive: state.roles.selectedRole === 3,
      isTopManagement: state.roles.selectedRole === 4,
      isHR: state.roles.selectedRole === 6,
      isHOD: state.roles.selectedRole === 5,
    }),
    getAppLanguage: state => state.appLanguage,
    userDetails: state => state.userDetails,
    userPerformance: state => state.userPerformance,
    userSetting: state => state.userSetting,
    base: state => state.base,
  },
  mutations: {
    setAppLanguage(state, language) {
      state.appLanguage = language
      // localStorage.setItem("appLanguage", language) // Whenever we change the appLanguage we save it to the localStorage
    },
    setUserProfile(state, data) {
      state.profile = data
    },
    assignUserData(state, data) {
      const roles = data.role

      // Do not use for now
      state.roles.isAdmin = roles.includes(1)
      state.roles.isUser = roles.includes(2)
      state.roles.isExecutive = roles.includes(3)
      state.roles.isTopManagement = roles.includes(4)
      state.roles.isHR = roles.includes(6)
      state.roles.isHOD = roles.includes(5)
      state.roles.default = roles

      state.userDetails = data.user
      state.userPerformance = data.user_performance
      state.userSetting = data.user_setting
      state.sajRanhillToken = data.token

      const currentUserStatus = roles[0]

      state.roles.selectedRole = currentUserStatus
    },
    resetVuexState(state) {
      Object.assign(state, getDefaultState())
    },
    setSelectedRole(state, role) {
      state.roles.selectedRole = role
    },
    setFontSize(state, size) {
      state.base.fontSize = size
    },
  },
  actions: {
    assignUserData(context, data) {
      context.commit('assignUserData', data)
    },
    resetVuexState(context) {
      context.commit('resetVuexState')
    },
    setAppLanguage(context, data) {
      context.commit('setAppLanguage', data)
    },
    setSelectedRole(context, role) {
      context.commit('setSelectedRole', role)
    },
    setFontSize(context, size) {
      context.commit('setFontSize', size)
    },
  },
  modules: {
  },
  plugins: [
    createVuexPersistedState({
      key: 'sessionRanhill',
      // storage: window.localStorage,
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
      whiteList: [],
      blackList: [],
    }),
  ],
})
