  <!-- #Change 2
  user dropdown for user shortcut options
  -->
<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userDetails.organisation_name }}
        </p>
        <!-- <span class="user-status">{{ userDetails.role[0].role_name }}</span> -->
      </div>
      <b-avatar
        size="40"
        :src="image"
        variant="light"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <!-- <feather-icon
          icon="UserIcon"
          size="22"
          stroke="blue"
        /> -->
      </b-avatar>
    </template>

    <!-- <b-dropdown-item
      :to="{ name: 'employee-profile' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profil</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    > -->
    <!-- <b-dropdown-item link-class="d-flex align-items-center">
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Peti Masuk</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span>
    </b-dropdown-item> -->

    <b-dropdown-divider />
    <!-- :to="{ name: 'my-profile' }" -->
    <b-dropdown-item
      v-b-modal.update-password
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>{{ $t('Password') }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>{{ $t('Log Out') }}</span>
    </b-dropdown-item>
    <b-modal
      id="update-password"
      :hide-header="true"
      :hide-footer="true"
      :centered="true"
      size="lg"
    >
      <!-- $bvModal.hide(nama modal id) untuk tutup modal-->
      <update-password
        :is-update="true"
        @close="$bvModal.hide('update-password')"
      />
    </b-modal>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BModal,
} from "bootstrap-vue"
// import { initialAbility } from '@/libs/acl/config'
import updatePassword from "@/component/update-password-modal.vue"
import { avatarText } from "@core/utils/filter"
import SAJToast from '@/component/saj-toastification.vue'
import { mapGetters, mapActions } from 'vuex'
import store from '@/store/index'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BModal,
    updatePassword,
  },
  data() {
    return {
      avatarText,
      image: null,
    }
  },
  mounted(){
    // console.log(this.userDetails)
    if (store.getters.sajRanhillToken !== null){
      this.getProfileImage()
    }
  },
  computed: {
    ...mapGetters(['userDetails']),
  },
  methods: {
    ...mapActions(['resetVuexState']),

    getProfileImage() {
      this.$axios
        .get(`${this.$baseUrl}/profile_picture/getProfilePicture`)
        .then(response => {
          const val = response.data.data.file

          // console.log('val', val)

          if (val === null) {
            this.image = null
          } else {
            this.image = val.url
          }
        })
    },

    logout() {
      // Redirect to login page
      // this.$router.push({ name: "login" })
      this.$axios.post(`${this.$baseUrl}/logout`).then(() => {
        this.resetVuexState()
        this.$router.replace({ name: 'login' })
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('You have successfully logout')}`,
              icon: 'UserIcon',
              titleColor: '#000',
            // iconBg: '#e61212',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
      })
    },
  },
}
</script>
