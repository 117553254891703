import Vue from 'vue'
import VueRouter from 'vue-router'
import hod from '@/router/module/head-of-department'
import hr from '@/router/module/human-resource'
import nonExec from '@/router/module/non-executive'
import topManagement from '@/router/module/top-management'
import executive from '@/router/module/executive'
import global from '@/router/module/global'
import store from '@/store/index'
import SAJToast from "@/component/saj-toastification.vue"
import other from './other'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior() {
    document.getElementById('baseTransitionGroup').scrollIntoView()
    document.getElementById('baseTransitionGroup').scrollTop = -40
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'login' } },
    ...hod,
    ...topManagement,
    ...hr,
    ...nonExec,
    ...executive,
    ...global,
    ...other,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
  linkActiveClass: "ranhill-router-link-exact-active",
  linkExactActiveClass: "ranhill-router-link-exact-active",
})

// // eslint-disable-next-line arrow-body-style
// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()
//   const unprotectedRoute = ['/forgotpassword', '/authpassword', '/resetpassword']
//   if (unprotectedRoute.includes(to.path)){
//     return next()
//   }
//   // console.log(isLoggedIn)
//   if (!canNavigate(to)) {
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'login' })

//     // // If logged in => not authorized
//     // return next({ name: 'auth-not-authorized' })
//   }

//   // Redirect if logged in
//   // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//   // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//   //   const userData = getUserData()
//   //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   // }

//   return next()
// })
// ? For splash screen
// Remove afterEach hook if you are not using splash screen

router.beforeEach((to, _, next) => {
  if (to.meta.auth === false) { // if page set to no auth
    next()
  } else if (store.getters.sajRanhillToken === null) { // if token is null which equals to user not login to the system
    Vue.$toast({
      component: SAJToast,
      props: {
        title: 'Session Expired',
        icon: 'XIcon',
        text: 'Please login.',
        variant: 'danger',
        titleColor: "#000",
        iconBg: '#e80202',
      },
    }, {
      timeout: 4000,
    })
    next("/")
  } else {
    next()
  }
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
