<template>
  <div class="ranhill-footer d-flex justify-content-center align-items-center">
    <span
      class="float-md-left d-block d-md-inline-block mt-25 mx-1"
      style="font-size: 0.9rem;"
    >
      {{ $t('Copyright') }}  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        style="color: red;"
        href="https://ranhillsaj.com.my/"
        target="_blank"
      >Ranhill</b-link>
      <!-- <span class="d-none d-sm-inline-block">, {{ $t('All rights Reserved') }}</span> -->
    </span>
    <!-- <Localization></Localization> -->
  </div>
</template>
<style scoped>
.ranhill-footer {
    background: #fff;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}
</style>

<script>
import { BLink } from 'bootstrap-vue'
// import Localization from '@/views/base/other/localization.vue'

export default {
  components: {
    BLink,
    // Localization,
  },
}
</script>
