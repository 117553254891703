<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left"
      style="margin-bottom: 5px;"
      cols="12"
      md="10"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <b-row>
            <h2
              class="saj-header float-left pr-1"
              style="letter-spacing: 0.3px;
                  color: #000000;"
            >
              {{ $t($route.meta.pageTitle) }}
            </h2>
          </b-row>

          <b-row class="breadcrumb-wrapper">
            <b-breadcrumb
              class="p-0 mb-0"
              style="font-family: Poppins;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 24px;
                  color: #000000 !important;"
            >
              <!-- <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item> -->
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
                class="d-flex align-items-center justify-content-center"
                style="font-size: 1rem;"
              >
                {{ $t(item.text) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </b-row>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <!-- <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    > -->
    <b-dropdown
      variant="link"
      no-caret
      toggle-class="p-0"
      right
    >

      <template #button-content>
        <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
          >
            <feather-icon icon="SettingsIcon" />
          </b-button> -->
      </template>

      <b-dropdown-item>
        <feather-icon
          icon="CheckSquareIcon"
          size="16"
        />
        <span class="align-middle ml-50">Todo</span>
      </b-dropdown-item>

      <b-dropdown-item>
        <feather-icon
          icon="MessageSquareIcon"
          size="16"
        />
        <span class="align-middle ml-50">Chat</span>
      </b-dropdown-item>            <b-dropdown-item>
        <feather-icon
          icon="MailIcon"
          size="16"
        />
        <span class="align-middle ml-50">Email</span>
      </b-dropdown-item>
      <b-dropdown-item>
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <span class="align-middle ml-50">Calendar</span>
      </b-dropdown-item>
    </b-dropdown>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
  },
}
</script>
