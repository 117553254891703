<template>
  <div
    id="app"
    style="height: 100vh; width: 100vw;"
    class="d-flex"
  >
    <transition
      name="slide-left"
      mode="out-in"
    >

      <nav-bar
        v-if="showSideBar"
        key="navBarTransition"
        :class="navBarExpanded? 'nav-bar-width-expanded' : 'nav-bar-width-collapse'"
        style="transition: ease-in-out 0.3s;"
        @expandNavBar="expandNavBarData"
      />
    </transition>
    <div
      class="d-flex flex-column"
      style="height: 100%; width: 100%;"
    >
      <horizontalNavBar
        v-if="layout !== 'full'"
        class=""
        style="width: 100%; height: 65px; background: #FF0000;"
      />
      <div style="height: 100%; width: 100%; overflow: hidden;">
        <div
          id="baseTransitionGroup"
          style="position: relative;height: 100%; width: 100%;"
        >
          <pageMeta
            v-if="layout!== 'full'"
            key="pageMeta"
            style="padding-top: 10px; padding-right: 50px; padding-left: 50px; height: auto; padding-bottom: 5px; "
            :layout="layout"
            :breadcrumb="breadcrumb"
            :page-title="pageTitle"
          />
          <transition
            name="zoom-fade"
            mode="out-in"
          >
            <router-view
              id="panelRouterView"
              key="panelRouterView2"
              class=""
              style="width: 100%; height: 92%; overflow: auto; position: absolute; padding-left: 20px; padding-right: 20px;"
            />
            <!-- <div
              v-if="isLoading"
              key="loadingIndicator"
              style="position:fixed; right: 17px; top: 69px;"
            >
              <b-spinner
                variant="primary"
                label="Loading..."
              />
            </div> -->
          </transition>
        </div>
      </div>
      <footer>
        <sajFooter
          v-if="layout!== 'full'"
          style="height: 40px; "
        /></footer>
    </div>
  </div>
</template>
<style>

:root {
  --size: 14px
}

:root{
  font-size: var(--size)
}
</style>
<script>
import navBar from '@/views/base/side-bar/main.vue'
import horizontalNavMenu from '@/views/base/horizontal-nav-menu/main.vue'
import pageMeta from '@/views/base/other/page-meta.vue'
import sajFooter from '@/views/base/other/AppFooter.vue'
import horizontalNavBar from '@/views/base/horizontal-nav-menu/horizontalNavBar.vue'
import { Base } from '@/menu/event-controller'
import { BSpinner } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

const r = document.querySelector(':root')

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    navBar, horizontalNavMenu, pageMeta, sajFooter, horizontalNavBar, BSpinner,
  },
  data(){
    return {
      navBarExpanded: true,
      showSideBar: false,
      isLoading: false,
    }
  },
  computed: {
    currentRoute() {
      return this.$route
    },
    breadcrumb() {
      if (this.$route.meta.breadcrumb === undefined) {
        return []
      }
      return this.$route.meta.breadcrumb
    },
    pageTitle() {
      if (this.$route.meta.pageTitle === undefined) {
        return ''
      }
      return this.$route.meta.pageTitle
    },
    layout() {
      if (this.$route.meta.layout === undefined) {
        return 'default'
      }
      return this.$route.meta.layout.toLowerCase()
    },
    ...mapGetters(['base']),
  },
  watch: {
    'base.fontSize': {
      handler(val) {
        return r.style.setProperty('--size', `${val}px`)
      },
    },
    currentRoute: {
      handler(val) {
        if (val.name === 'login') { // force sidebar to close if start over from login
          // this.navBarExpanded = false
        }
        const layout = val.meta.layout === undefined || val.meta.layout === null ? 'default' : val.meta.layout
        this.showSideBar = layout.toLowerCase() !== 'full'
      },
    },
  },
  beforeDestroy(){
    // Base.$off('change-font')
  },
  mounted() {
    // Base.$on('change-font', val => {
    //   this.changeFont(val)
    // })
    Base.$on('loading', val => {
      this.isLoading = val
    })
  },
  methods: {
    expandNavBarData(status){
      this.navBarExpanded = status
    },

  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
