/* eslint-disable no-unused-vars */
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VSwitch from 'v-switch-case'
// "vue-lazyload": "1.3.3",
import 'flatpickr/dist/flatpickr.css'

import i18n from '@/libs/i18n'

import axios from "axios"
import Toast from "vue-toastification" // Toast
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import api from '@/api-service'
import SAJToast from "@/component/saj-toastification.vue"
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'
import '@/views/base/css/global.css'

import '@/libs/toastification'

import 'vue-slider-component/theme/antd.css'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.config.productionTip = false
Vue.config.performance = process.env.NODE_ENV !== "production"

const productionUrl = "https://api.ranhill-pms.com/api"
// eslint-disable-next-line no-unused-vars
const stagingUrl = "http://staging-api.ranhill-pms.com/api"
// eslint-disable-next-line no-unused-vars
const developmentUrl = "http://52.74.19.0:8000/api"
// const developmentUrl = "http://13.214.8.186:8080/api"
// eslint-disable-next-line no-unused-vars
Vue.prototype.$baseUrl = productionUrl

Vue.prototype.$axios = axios

axios.interceptors.request.use(
  config => {
    const token = store.getters.sajRanhillToken
    const auth = token ? `Bearer ${token}` : ''
    // eslint-disable-next-line no-param-reassign
    config.headers.common.Authorization = auth
    return config
  },
  error => {
    Promise.reject(error)
  },
)

axios.interceptors.response.use(response => response, error => {
  // console.log('apa', error.response)
  // if (error.response.hasOwnProperty('status')) {
  if (error.response.status === 401) {
    store.dispatch('resetVuexState')
    router.push({ name: 'login' }).then(() => {
      Vue.$toast({
        component: SAJToast,
        props: {
          title: 'Session Expired',
          icon: 'XIcon',
          text: 'Please login.',
          variant: 'danger',
          titleColor: "#000",
          iconBg: '#e80202',
        },
      }, {
        timeout: 4000,
      })
    }).catch(() => { })
  }
  // }
  return Promise.reject(error)
})

Vue.use(VSwitch)
Vue.use(Toast) // Toast
// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  // let language = to.params.lang
  // if (!language) {
  //   language = 'en'
  // }

  // // set the current language for i18n.
  // i18n.locale = language
  next()
})

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
