export default [
  {
    path: '/performance',
    name: 'perf',
    component: () => import('@/pages/non-exec/Performance.vue'),
    meta: {
      // layout: 'full',
      pageTitle: 'Prestasi',
      // breadcrumb: [
      //   {
      //     text: 'page4',
      //     active: true,
      //   },
      //   {
      //     text: 'page2',
      //     to: { name: 'page2' },
      //   },
      // ],
    },
  },

]
