export default [
  {
    path: '/performance-scale',
    name: 'performance-scale',
    component: () => import('@/pages/hod/performance-scale.vue'),
    meta: {
      // layout: 'full',
      pageTitle: 'Performance Scale',
    },
  },
  {
    path: '/performance-scale-details',
    name: 'performance-details',
    component: () => import('@/component/hod/EditEmployee.vue'),
    // meta: {
    //   // layout: 'full',
    //   pageTitle: 'Performance Scale',
    // },
  },
]
