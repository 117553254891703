<template>
  <div style="background: #fff">
    <div
      class="d-flex flex-column py-1"
      style="height: 100%; padding: 0rem !important"
    >
      <div
        class="saj-ranhill-nav-base d-flex flex-column align-items-center"
        style="padding-left: 8px; padding-right: 8px; height: 100%;"
      >

        <div
          class="d-flex flex-column"
          style="width: 100%; height: 100%;"
        >
          <company-logo
            :nav-bar-expanded="navBarExpanded"
            @retrigger="retrigger"
          />
          <div
            class="mt-1 d-flex flex-column"
            style="width: 100%; height: 100%; overflow-y: auto; overflow-x:hidden"
          >
            <transition-group
              name="zoom-fade-hide"
              mode="out-in"
              class="d-flex flex-wrap justify-content-center align-items-center m-1"
              style="height: auto;"
            >
              <nav-bar-item
                v-for="item in roleItems"
                :key="item.route"
                class="saj-subtitle"
                :style="{width: navBarExpanded ? '230px' : 'auto'}"
                :status="navBarExpanded"
                :title="item.title"
                :route="item.route"
                :icon="item.icon"
              />
            </transition-group>
          </div>
          <b-form-select
            v-model="selectedrole"
            class="mb-1 mt-1"
            :options="rolesList"
            :close-on-select="true"
          />
          <!-- <div
            style="position: relative;"
          >
            <Localization
              :nav-bar-expanded="navBarExpanded"
              style="width: 40px;"
            />
          </div> -->
        </div>
      </div>
    </div>

  </div>
</template>
<style scoped>
.saj-ranhill-nav-base {
  box-shadow: 0 0 15px 0 rgb(34 41 47 / 5%);
}

</style>
<script>
// import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import navBarItem from '@/views/base/side-bar/components/nav-bar-item.vue'
import companyLogo from '@/views/base/side-bar/components/organization-nav-bar.vue'
import NavItems from '@/views/base/side-bar/nav-items'
// import Localization from '@/views/base/other/localization.vue'
import { mapGetters, mapActions } from 'vuex'
import {
  BFormSelect,

} from "bootstrap-vue"

export default {
  components: {
    // Localization,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    navBarItem,
    companyLogo,
  },
  props: {
  },
  data(){
    return {
      index: 1,
      roleItems: [],
      rolesList: [],
      isGrid: false,
      // userPermission: [],
      NavItems,
      navBarExpanded: true,
      addedSection: false,
      addedDiv: false,
      isSection: false,
      isDivision: false,
    }
  },
  computed: {
    ...mapGetters(['roles']),
    selectedrole: {
      get() {
        return this.roles.selectedRole
      },
      set(value) {
        // this.$store.commit('updateMessage', value)
        this.setSelectedRole(value)
        this.getCurrItems()

        // eslint-disable-next-line no-plusplus
        this.index += 2

        // const isAdmin = response.data.data.role.includes(1)

        let whichRoute = ''
        // console.log('val', value)

        switch (value){
          case 1:
            whichRoute = 'create-company'
            break
          case 2:
            whichRoute = 'dashboard'
            break
          case 6:
            whichRoute = 'create-company'
            break
          default:
            whichRoute = 'dashboard'
        }
        this.$router
          .replace({ name: whichRoute, params: { rerender: this.index } }).then(() => {
            this.getCurrItems(value)
          }).catch(() => {})
      },
    },
  },
  watch: {
    navBarExpanded: {
      handler(val) {
        this.$emit('expandNavBar', val)
      },
    },
  },
  mounted() {
    this.getRole()
  },
  methods: {
    ...mapActions(['resetVuexState', 'setSelectedRole']),
    getRole() {
      this.$axios.get(`${this.$baseUrl}/users/current_user`).then(response => {
        const roleID = response.data.data.role
        const roleName = response.data.data.role_name
        this.allRoles = response.data.data.role_name
        this.allRoles.forEach((role, index) => {
          this.rolesList.push({
            text: roleName[index],
            value: roleID[index],
          })
        })

        // console.log('user', response.data.data.division !== null)

        this.isSection = response.data.data.employee.head_of_section
        this.isDivision = response.data.data.division !== null

        this.getCurrItems(this.selectedrole)

        // console.log('section', response.data.data.employee.head_of_section)
      })
    },
    getCurrItems(role = this.roles.selectedRole){
      if (this.addedSection === false){
        if (this.isSection){
          this.addedSection = true
          const a = {
            title: 'Section Report',
            route: 'section-summary-report',
            icon: 'fas fa-file-text',
            type: 'fontAwesome',
            role: [3, 4],
            nameRole: ['Executive', 'Top Management'],
          }

          this.NavItems = this.NavItems.concat(a)
        }
      }

      if (this.addedDiv === false){
        if (this.isDivision){
          this.addedDiv = true
          const a = [
            {
              title: 'Performance Statistics',
              route: 'statistics',
              icon: 'fas fa-bar-chart',
              type: 'fontAwesome',
              role: [4],
              nameRole: ['Top Management'],
            },
            {
              title: 'Division Report',
              route: 'division-report',
              icon: 'fas fa-file-text',
              type: 'fontAwesome',
              role: [4],
              nameRole: ['Top Management'],
            },
          ]

          this.NavItems = this.NavItems.concat(a)
        }
      }

      // console.log('role main', this.roles.selectedRole)
      this.roleItems = []

      setTimeout(() => {
        this.roleItems = this.NavItems.filter(x => {
          if (x.role !== undefined) {
            return x.role.includes(role)
          }
          return false
        })
      }, 200)
    },
    retrigger(status){
      this.navBarExpanded = status
    },
    apiLogout(){
      this.resetVuexState()
      this.$router.replace({ name: 'login' }).then(() => {
        this.$toast({
          props: {
            // title: `Successfully Log Out`,
            icon: "CoffeeIcon",
            variant: "primary",
            text: `Successfully logged out.`,
          },
          position: "top-right",
        })
      })
    },
  },
}
</script>
