<template>
  <b-nav-item-dropdown
    class=""
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="total"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
        stroke="white"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('Notifications') }}
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ notifications.length }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->

    <!-- Account Notification -->
    <div
      style="min-height: 10px;
             max-height: 40vh;
             overflow: auto;
             width: 30vw;
             "
    >
      <transition-group
        name="list"
        mode="out-in"
      >
        <div
          v-for="(notification) in notifications"
          :key="notification.id"
        >

          <div class="m-0 mt-1 p-0 pl-1 pr-1">
            <!-- <template #aside> -->
            <!-- <b-avatar
            size="32"
            :src="notification.avatar"
            :text="notification.avatar"
            :variant="notification.type"
          /> -->
            <!-- </template> -->
            <p
              class="d-flex justify-content-between"
              style="font-size: 1rem"
            >
              <span class="font-weight-bolder">
                {{ notification.title }} <small>{{ DateChanged(notification.created_at) }}</small>
              </span>
              <feather-icon
                class="saj-scale-1 pointer"
                icon="XIcon"
                size="20"
                @click="getRead(notification.id)"
              />
            </p>
            <p>

              <small class="notification-text">{{ notification.message }}</small></p>
            <a
              v-if="notification.file !== null"
              class="m-0"
              :href="notification.file"
              target="_blank"
              style="
                     text-decoration: underline;
                     color: rgb(85, 85, 255);
                     font-style: italic;
                     font-size: 0.8rem;
                    "
            >
              {{ 'Click here to download' }}
            </a>

            <!-- <hr style="border-bottom: 2px solid #FF0000"> -->
            <hr>
          </div>
        </div>
      </transition-group>
    </div>

    <!-- System Notification Toggler -->
    <!-- <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div> -->

    <!-- System Notifications -->
    <!-- <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link> -->

    <!-- Cart Footer -->
    <!-- <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Read all notifications</b-button>
    </li> -->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge,
  // BMedia,
  // BLink,
  // BAvatar,
  // BButton,
  // BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import axios from "axios"
import moment from "moment"

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    // BMedia,
    // BLink,
    // BAvatar,
    // BButton,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      notifications: [
        // {
        //   title: "",
        //   message: "",
        // },
      ],
      read: 0,
      total: 0,
    }
  },
  mounted(){
    // // setInterval(this.getNotification(), 3000)

    window.setInterval(() => {
      if (store.getters.sajRanhillToken !== null){
        this.getNotification()
        // console.log(store.getters.sajRanhillToken)
      }
    }, 10000)
  },
  created() {

  },
  methods: {
    getNotification(){
      // console.log('kalau ada', store.getters.sajRanhillToken)
      // console.log('noti')
      axios.get(`${this.$baseUrl}/notification/get_notification_all`).then(response => {
        // if (response.data.success === true){
        this.notifications = response.data.data
        this.total = this.notifications.length
        // console.log(this.notifications)
        // console.log(store.getters.sajRanhillToken)
      })
    },
    getRead(idd) {
      axios.post(`${this.$baseUrl}/notification/update_notification`, { id: idd, is_read: '1' }).then(() => {
        this.getNotification()
      })
    },

    DateChanged(date) {
      const newDay = moment(date).format("D-MMM-yyyy")
      return newDay
    },
  },
  setup() {
    /* eslint-disable global-require */
    // const notifications = [
    //   {
    //     title: 'Congratulation Sam 🎉',
    //     avatar: require('@/assets/images/avatars/6-small.png'),
    //     subtitle: 'Won the monthly best seller badge',
    //     type: 'light-success',
    //   },
    //   {
    //     title: 'New message received',
    //     avatar: require('@/assets/images/avatars/9-small.png'),
    //     subtitle: 'You have 10 unread messages',
    //     type: 'light-info',
    //   },
    //   {
    //     title: 'Revised Order 👋',
    //     avatar: 'MD',
    //     subtitle: 'MD Inc. order updated',
    //     type: 'light-danger',
    //   },
    // ]
    /* eslint-disable global-require */

    // const systemNotifications = [
    //   {
    //     title: 'Server down',
    //     subtitle: 'USA Server is down due to hight CPU usage',
    //     type: 'light-danger',
    //     icon: 'XIcon',
    //   },
    //   {
    //     title: 'Sales report generated',
    //     subtitle: 'Last month sales report generated',
    //     type: 'light-success',
    //     icon: 'CheckIcon',
    //   },
    //   {
    //     title: 'High memory usage',
    //     subtitle: 'BLR Server using high memory',
    //     type: 'light-warning',
    //     icon: 'AlertTriangleIcon',
    //   },
    // ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      // notifications,
      // systemNotifications,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>
/* .show > .dropdown-menu {
    transform: scale(1, 1);
    opacity: 1;
    display: block;
    min-height: 20vh;
    max-height: 40vh;
    overflow: auto;
    width: 30vw;
} */
</style>
