const navItems = [
  {
    title: 'Company Info',
    route: 'create-company',
    icon: 'fas fa-building',
    type: 'fontAwesome',
    role: [1, 6],
    nameRole: ['Admin', 'Human Resources'],
  },
  // {
  //   title: 'Profile Pekerja',
  //   route: 'profile-staff',
  //   icon: 'fas fa-user-tie',
  //   type: 'fontAwesome',
  //   role: [6],
  //   nameRole: ['Human Resource'],
  // },
  // {
  //   title: 'Setting JD/KRA',
  //   route: 'setting-jd-kra',
  //   icon: 'fas fa-user-tie',
  //   type: 'fontAwesome',
  //   role: [1],
  //   nameRole: ['Admin'],
  // },
  // {
  //   title: 'Senarai Skor Pekerja',
  //   route: 'senarai-skor-pekerja',
  //   icon: 'fas fa-user-tie',
  //   type: 'fontAwesome',
  //   role: [6],
  //   nameRole: ['Human Resource'],
  // },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'fas fa-dashboard',
    type: 'fontAwesome',
    role: [2, 3, 4, 5],
    nameRole: ['User', 'Executive', 'Top Management', 'Head of Department'],
  },
  {
    title: 'My Profile',
    route: 'my-profile',
    icon: 'fas fa-user-tie',
    type: 'fontAwesome',
    role: [2, 3, 4],
    nameRole: ['User', 'Executive', 'Top Management'],
  },
  {
    title: 'Project Management',
    route: 'project-management',
    icon: 'fas fa-clipboard-check',
    type: 'fontAwesome',
    role: [2, 3, 4],
    nameRole: ['User', 'Executive', 'Top Management'],
  },
  {
    title: 'My Performance Cycle',
    route: 'performance-cycle',
    icon: 'fas fa-chart-pie',
    type: 'fontAwesome',
    role: [2, 3, 4],
    nameRole: ['User', 'Executive', 'Top Management'],
  },
  {
    title: 'My Summary Score',
    route: 'summary-score',
    icon: 'fas fa-table',
    type: 'fontAwesome',
    role: [4, 3, 2],
    nameRole: ['Top Management', 'Executive', 'User'],
  },
  {
    title: 'Team Profile',
    route: 'team-profile',
    icon: 'fas fa-users',
    type: 'fontAwesome',
    role: [1, 3, 4, 5, 6],
    nameRole: ['Admin', 'Executive', 'Top Management', 'Head of Department', 'Human Resourcess'],
  },
  {
    title: 'Team Performance',
    route: 'team-performance',
    icon: 'fas fa-users',
    type: 'fontAwesome',
    role: [4, 3],
    nameRole: ['Top Management', 'Executive'],
  },
  {
    title: 'Performance Management Summary',
    route: 'summary-report',
    icon: 'fas fa-file-contract',
    type: 'fontAwesome',
    role: [5, 6, 1],
    nameRole: ['Head of Department', 'Human Resources', 'Admin'],
  },
  {
    title: 'Performance Statistics',
    route: 'performance-statistics',
    icon: 'fas fa-bar-chart',
    type: 'fontAwesome',
    role: [1, 5, 6],
    nameRole: ['Admin', 'Head of Department', 'Human Resources'],
  },
  // {
  //   title: 'Performance Statistics',
  //   route: 'statistics',
  //   icon: 'fas fa-bar-chart',
  //   type: 'fontAwesome',
  //   role: [4],
  //   nameRole: ['Top Management'],
  // },
  // {
  //   title: 'Performance Scale',
  //   route: 'performance-scale',
  //   icon: 'fas fa-chart-line',
  //   type: 'fontAwesome',
  //   role: [5],
  //   nameRole: ['Head of Department'],
  // },
  // {
  //   title: 'Transfer/Promotion',
  //   // route: 'summary-report',
  //   icon: 'fas fa-chart-line',
  //   type: 'fontAwesome',
  //   role: [6, 1],
  //   nameRole: ['Human Resources', 'Admin'],
  // },
  // {
  //   title: 'My Performance (New)',
  //   route: 'my-performance',
  //   icon: 'fas fa-chart-pie',
  //   type: 'fontAwesome',
  //   role: [2, 3, 4, 5],
  //   nameRole: ['User', 'Executive', 'Top Management', 'Head of Department'],
  // },
  {
    title: 'Theme',
    route: 'theme-setting',
    icon: 'fas fa-database',
    type: 'fontAwesome',
    role: [1, 5, 6],
    // role: [5],
    nameRole: ['Admin', 'Head of Department', 'Human Resources'],
    // nameRole: ['Head of Department'],
  },
  // {
  //   title: 'Competency Management',
  //   route: 'competency-management',
  //   icon: 'fas fa-list-check',
  //   type: 'fontAwesome',
  //   role: [1],
  //   nameRole: ['Admin'],
  // },
  // {
  //   title: 'HR Approve Education',
  //   route: 'approve-education',
  //   icon: 'fas fa-chart-line',
  //   type: 'fontAwesome',
  //   role: [1],
  //   nameRole: ['Admin'],
  // },
  // {
  //   title: 'work-loc-modal',
  //   route: 'work-loc-modal',
  //   icon: 'fas fa-chart-line',
  //   type: 'fontAwesome',
  //   role: [1],
  //   nameRole: ['Admin'],
  // },
  {
    title: 'Setting',
    route: 'all-setting',
    icon: 'fas fa-gear',
    type: 'fontAwesome',
    role: [1, 6],
    nameRole: ['Admin', 'Human Resource'],
  },
  // {
  //   title: 'Education',
  //   route: 'request-change',
  //   icon: 'fas fa-book-open',
  //   type: 'fontAwesome',
  //   role: [1],
  //   nameRole: ['Admin'],
  // },
  {
    title: 'Employee Management',
    route: 'employee-management',
    icon: 'fas fa-address-book',
    type: 'fontAwesome',
    role: [1],
    nameRole: ['Admin'],
  },
  {
    title: 'Division Management',
    route: 'division-management',
    icon: 'fas fa-building',
    type: 'fontAwesome',
    role: [1],
    nameRole: ['Admin'],
  },
  {
    title: 'Score Management',
    route: 'score-management',
    icon: 'fas fa-percentage',
    type: 'fontAwesome',
    role: [1],
    nameRole: ['Admin'],
  },
  {
    title: 'Training Record',
    route: 'training',
    icon: 'fas fa-chalkboard-user',
    type: 'fontAwesome',
    role: [1, 6],
    nameRole: ['Admin', 'Human Resource'],
  },
  // {
  //   title: 'Section Management',
  //   route: 'section',
  //   icon: 'fas fa-user-group',
  //   type: 'fontAwesome',
  //   role: [1],
  //   nameRole: ['Admin'],
  // },
  {
    title: 'Section Management',
    route: 'section',
    icon: 'fas fa-user-group',
    type: 'fontAwesome',
    role: [1],
    nameRole: ['Admin'],
  },
  {
    title: 'Weightage Management',
    route: 'weightage',
    icon: 'fas fa-calculator',
    type: 'fontAwesome',
    role: [1],
    nameRole: ['Admin'],
  },
  {
    title: 'Reference',
    route: 'reference',
    icon: 'fas fa-spell-check',
    type: 'fontAwesome',
    role: [1, 2, 3, 4, 5, 6],
    nameRole: ['Admin', 'User', 'Executive', 'Top Management', 'Head of Department', 'Human Resource'],
  },
]

export default navItems
