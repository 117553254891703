export default [
// hr
  {
    path: '/register-staff/:id',
    name: 'register-staff',
    component: () => import('@/pages/hr/register-staff.vue'),
    meta: {
      // // layout: 'full',
      pageTitle: 'Register Company',
      activePage: 'create-company',
      breadcrumb: [
        {
          text: 'New Company',
          to: { name: 'create-company' },
        },
        {
          text: 'Register Company',
          active: true,
        },
      ],
    },
  },
  {
    path: '/create-company/:rerender?',
    name: 'create-company',
    component: () => import('@/pages/hr/create-company.vue'),
    meta: {
      // // layout: 'full',
      pageTitle: 'Company',
    },
  },
  {
    path: '/profile-staff',
    name: 'profile-staff',
    component: () => import('@/pages/hr/profile-staff.vue'),
    meta: {
      // // layout: 'full',
      pageTitle: 'Employee Profile',
    },
  },
  {
    path: '/setting-jd-kra',
    name: 'setting-jd-kra',
    component: () => import('@/pages/hr/setting-JD-KRA.vue'),
    meta: {
      // // layout: 'full',
      pageTitle: 'Setting JD KRA',
    },
  },
  {
    path: '/senarai-skor-pekerja',
    name: 'senarai-skor-pekerja',
    component: () => import('@/pages/hr/all-score-staff.vue'),
    meta: {
      // // layout: 'full',
      pageTitle: 'Senarai Skor Pekerja',
    },
  },
  {
    path: '/education',
    name: 'education',
    component: () => import('@/pages/hr/education.vue'),
    meta: {
      pageTitle: 'Education',
    },
  },
  {
    path: '/approve-education',
    name: 'approve-education',
    component: () => import('@/pages/hr/approve-education.vue'),
    meta: {
      pageTitle: 'HR Approve Education',
    },
  },
  // {
  //   path: '/setting2',
  //   name: 'setting2',
  //   component: () => import('@/pages/hr/setting2.vue'),
  //   meta: {
  //     // // layout: 'full',
  //     pageTitle: 'Setting2',
  //   },
  // },
  // {
  //   path: '/all-setting',
  //   name: 'all-setting',
  //   component: () => import('@/pages/hr/all-setting.vue'),
  //   meta: {
  //     pageTitle: 'Settings',
  //   },
  // },
  {
    path: '/work-loc-modal',
    name: 'work-loc-modal',
    component: () => import('@/component/work-location-modal.vue'),
    meta: {
      pageTitle: 'Work Location Modal',
    },
  },
  {
    path: '/request-change',
    name: 'request-change',
    component: () => import('@/pages/hr/request-change.vue'),
    meta: {
      // // layout: 'full',
      pageTitle: 'Education',
    },
  },
  {
    path: '/jdkra-setting',
    name: 'jdkra-setting',
    component: () => import('@/pages/hr/setting-JD-KRA.vue'),
    meta: {
      // // layout: 'full',
      pageTitle: 'JD KRA',
    },
  },
]
