<template>
  <div class="">
    <pageBreadcrumb />
  </div>
</template>
<script>
import pageBreadcrumb from '@/views/base/other/AppBreadcrumb.vue'

export default {
  components: {
    pageBreadcrumb,
  },
  props: {
    layout: {
      type: String,
      required: false,
      default: 'default',
    },
    pageTitle: {
      type: String,
      required: false,
      default: 'default',
    },
    breadcrumb: {
      type: Array,
      required: false,
      default: () => ([]),
    },

  },
  data(){
    return {

    }
  },
  mounted(){

  },
  methods: {

  },
}
</script>
