<template>
  <div
    style="width: 100%;"
    @click="$emit('click', $event)"
  >
    <div
      style="cursor:pointer; width: 100%;"
      :class="navBarExpanded? 'justify-content-between' : 'justify-content-center'"
      class="mb-2 d-flex flex-column align-items-center"
    >
      <div
        style="width: 100%; margin-top: 10px;"
        :style="navBarExpanded ? 'margin-right: 15px;' : ''"
        class="d-flex align-items-center"
        :class="navBarExpanded ? 'justify-content-end' : 'justify-content-center'"
      >
        <feather-icon
          :icon="isMouseOn? navBarExpanded ? 'CircleIcon' : 'DiscIcon' : navBarExpanded ? 'DiscIcon' : 'CircleIcon'"
          class="hoverExpandedIcon"
          size="22"
          @mouseover="isMouseOn = true"
          @mouseleave="isMouseOn = false"
          @click="expandNavBar"
        />
      </div>

      <div
        v-if="navBarExpanded"
        style="margin-top: 15%; width: 100%;"
        class="d-flex justify-content-center align-items-center"
      >
        <!-- @click="$router.replace({ name: 'dashboard' }).catch(() => { })" -->
        <div
          style=""
        >
          <img
            style="width: 120px;"
            src="@/assets/images/logo/logo-ranhill2.svg"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {
    navBarExpanded: {
      type: Boolean,
      required: true,
    },

  },
  data(){
    return {
      isMouseOn: false,

    }
  },
  mounted(){

  },
  methods: {
    expandNavBar(){
      this.$emit('retrigger', !this.navBarExpanded)
    },

  },
}
</script>
<style scoped>
.hoverExpandedIcon {
  color: #6E6B7B;

}
.hoverExpandedIcon:hover {
  color: #FF0000;

}
.companyLogo{
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  letter-spacing: -0.428571px;

  /* System/Secondary/50 */

  color: #B0BEC5;
}
</style>
