export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/pages/dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
    },
  },
  {
    path: '/project-management',
    name: 'project-management',
    component: () => import('@/views/pages/project-management.vue'),
    meta: {
      pageTitle: 'Project Management',
    },
  },
  {
    path: '/View-Member/:id',
    name: 'View-Member',
    component: () => import('@/component/projectManagement/view-project-member.vue'),
    meta: {
      pageTitle: 'Project Member',
      breadcrumb: [
        {
          text: 'Project Management',
          to: { name: 'project-management' },
        },
        {
          text: 'View Project Member',
          active: true,
        },
      ],
    },
  },
  {
    path: '/View-Assigned-Member/:id',
    name: 'View-Assigned-Member',
    component: () => import('@/component/projectManagement/view-assigned-project-member.vue'),
    meta: {
      pageTitle: 'Project Member',
      breadcrumb: [
        {
          text: 'Project Management',
          to: { name: 'project-management' },
        },
        {
          text: 'View Assigned Project Member',
          active: true,
        },
      ],
    },
  },
  {
    path: '/performance-statistics',
    name: 'performance-statistics',
    component: () => import('@/views/pages/performance-statistics/performance-statistics.vue'),
    meta: {
      pageTitle: 'Performance Statistics',
    },
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('@/views/pages/performance-statistics/performance-statistics_division.vue'),
    meta: {
      pageTitle: 'Division Performance Statistics',
    },
  },
  {
    path: '/my-profile',
    name: 'my-profile',
    component: () => import('@/views/pages/profile/team-profile-individual-global.vue'),
    meta: {
      pageTitle: 'My Profile',
    },
  },
  {
    path: '/team-profile',
    name: 'team-profile',
    component: () => import('@/views/pages/profile/team-profile-global.vue'),
    meta: {
      pageTitle: 'Team Profile',
    },
  },
  {
    path: '/team-profile-individual/:id?/:subId?',
    name: 'team-profile-individual',
    component: () => import('@/views/pages/profile/team-profile-individual-global.vue'),
    meta: {
      pageTitle: 'Individual Profile',
      activePage: 'team-profile',
      breadcrumb: [
        {
          text: 'Team Profile',
          to: { name: 'team-profile' },
        },
        {
          text: 'Individual Profile',
          active: true,
        },
      ],
    },
  },
  {
    path: '/team-performance/:tabIdx?',
    name: 'team-performance',
    component: () => import('@/views/pages/performance/team-performance-global.vue'),
    meta: {
      pageTitle: 'Team Performance',
    },
  },
  {
    path: '/team-performance-individual/:id/:performance_id/:tabIdx?',
    name: 'team-performance-individual',
    component: () => import('@/views/pages/performance/team-performance-individual-global.vue'),
    meta: {
      pageTitle: 'Individual Performance',
      activePage: 'team-performance',
      breadcrumb: [
        {
          text: 'Team Performance',
          to: { name: 'team-performance', params: { tabIdx: 1 } },
        },
        {
          text: 'Individual Performance',
          active: true,
        },
      ],
    },
  },
  // For Sv (Team Performance)
  {
    path: '/team-performance-individual-part1/:id/:performance_id',
    name: 'team-performance-individual-part1',
    component: () => import('@/views/pages/JD-KRA-Team/JD/part-1-global-kra.vue'),
    meta: {
      pageTitle: 'Individual Performance',
      activePage: 'team-performance',
      breadcrumb: [
        {
          text: 'Team Performance',
          to: { name: 'team-performance' },
        },
        {
          text: 'Individual Performance',
          active: true,
        },
      ],
    },
  },
  // For HOD/HR/SHR/Section/Division (Performance Details)
  {
    path: '/performance-details-individual-part1/:id/:performance_id',
    name: 'performance-details-individual-part1',
    component: () => import('@/views/pages/summary/part-1-global-kra-performance-details.vue'),
    meta: {
      pageTitle: 'Individual Performance',
      activePage: 'summary-report',
      breadcrumb: [
        {
          text: 'Performance Details',
          to: { name: 'summary-report', params: { tabIdx: 1 } },
        },
        {
          text: 'Individual Performance',
          active: true,
        },
      ],
    },
  },
  // For Division (Performance Details)
  {
    path: '/performance-details-individual-part1-division/:id/:performance_id',
    name: 'performance-details-individual-part1-division',
    component: () => import('@/views/pages/summary/part-1-global-kra-performance-details.vue'),
    meta: {
      pageTitle: 'Individual Performance',
      activePage: 'division-report',
      breadcrumb: [
        {
          text: 'Performance Details',
          to: { name: 'division-report' },
        },
        {
          text: 'Individual Performance',
          active: true,
        },
      ],
    },
  },
  // For Section (Performance Details)
  {
    path: '/performance-details-individual-part1-section/:id/:performance_id',
    name: 'performance-details-individual-part1-section',
    component: () => import('@/views/pages/summary/part-1-global-kra-performance-details.vue'),
    meta: {
      pageTitle: 'Individual Performance',
      activePage: 'section-summary-report',
      breadcrumb: [
        {
          text: 'Performance Details',
          to: { name: 'section-summary-report' },
        },
        {
          text: 'Individual Performance',
          active: true,
        },
      ],
    },
  },
  {
    path: '/summary-score',
    name: 'summary-score',
    component: () => import('@/views/pages/summary/summary-score-global.vue'),
    meta: {
      pageTitle: 'Summary Score',
    },
  },
  // Summary Report Global
  {
    path: '/summary-report/:tabIdx?',
    name: 'summary-report',
    component: () => import('@/views/pages/summary/summary-report-main.vue'),
    meta: {
      pageTitle: 'Performance Management Summary',
    },
  },
  // Summary Report for Top Management
  {
    path: '/summary-report-tm',
    name: 'summary-report-tm',
    component: () => import('@/views/pages/summary/summary-report-top-management.vue'),
    meta: {
      pageTitle: 'Performance Management Summary',
    },
  },
  {
    path: '/test-atiqah',
    name: 'test-atiqah',
    component: () => import('@/component/29_profile_summary.vue'),
    meta: {
      pageTitle: 'testing atiqah',
    },
  },
  {
    path: '/my-performance/:id/:status/:tabNo?',
    name: 'my-performance',
    component: () => import('@/views/pages/performance/my-performance/my-performance-global.vue'),
    meta: {
      pageTitle: 'My Performance',
      activePage: 'performance-cycle',
      breadcrumb: [
        {
          text: 'Performance Cycle',
          to: { name: 'performance-cycle' },
        },
        {
          text: 'My Performance',
          active: true,
        },
      ],
    },
  },
  {
    path: '/my-performance-part1/:id/:status',
    name: 'my-performance-part1',
    component: () => import('@/views/pages/performance/my-performance/KRA/KRA-main.vue'),
    meta: {
      pageTitle: 'Key Result Area Details',
      activePage: 'performance-cycle',
      breadcrumb: [
        {
          text: 'Performance Cycle',
          to: { name: 'performance-cycle' },
        },
        {
          text: 'My Performance',
          to: { name: 'my-performance' },
        },
        {
          text: 'Key Result Area Details',
          active: true,
        },
      ],
    },
  },

  {
    path: '/my-performance-nonexec-part1/:id/:status',
    name: 'my-performance-nonexec-part1',
    component: () => import('@/pages/non-exec/JD/Part-1.vue'),
    meta: {
      pageTitle: 'Job Description Details',
      activePage: 'performance-cycle',
      breadcrumb: [
        {
          text: 'Performance Cycle',
          to: { name: 'performance-cycle' },
        },
        {
          text: 'My Performance',
          to: { name: 'my-performance' },
        },
        {
          text: 'Job Description Details',
          active: true,
        },
      ],
    },
  },

  {
    path: '/my-performance-nonexec-part1-kra/:id/:status',
    name: 'my-performance-nonexec-part1-kra',
    component: () => import('@/pages/non-exec/KRA/Part-1-new.vue'),
    meta: {
      pageTitle: 'Key Result Area Details',
      activePage: 'performance-cycle',
      breadcrumb: [
        {
          text: 'Performance Cycle',
          to: { name: 'performance-cycle' },
        },
        {
          text: 'My Performance',
          to: { name: 'my-performance' },
        },
        {
          text: 'Key Result Area Details',
          active: true,
        },
      ],
    },
  },

  {
    path: '/performance-cycle',
    name: 'performance-cycle',
    component: () => import('@/views/pages/performance/my-performance/performance-cycle.vue'),
    meta: {
      pageTitle: 'Performance Cycle',
      activePage: 'my-performance',
    },
  },
  {
    path: '/theme-setting',
    name: 'theme-setting',
    component: () => import('@/pages/hod/theme.vue'),
    meta: {
      pageTitle: 'Theme',
    },
  },
  {
    path: '/all-setting',
    name: 'all-setting',
    component: () => import('@/pages/hr/all-setting.vue'),
    meta: {
      pageTitle: 'Settings',
    },
  },
  {
    path: '/view-company/:id',
    name: 'view-company',
    component: () => import('@/pages/hr/register-staff.vue'),
    meta: {
      activePage: 'create-company',
      pageTitle: 'Company Details',
      breadcrumb: [
        {
          text: 'Company',
          to: { name: 'create-company' },
        },
        {
          text: 'View Company',
          active: true,
        },
      ],
    },
  },
  {
    path: '/employee-management',
    name: 'employee-management',
    component: () => import('@/views/pages/employee-management/transfer-promotion.vue'),
    meta: {
      pageTitle: 'Employee Management',
      // breadcrumb: [
      //   {
      //     text: 'Company',
      //     to: { name: 'create-company' },
      //   },
      //   {
      //     text: 'View Company',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: '/competency-management',
    name: 'competency-management',
    component: () => import('@/component/competency/competency_main.vue'),
    meta: {
      pageTitle: 'Competency Management',
      // breadcrumb: [
      //   {
      //     text: 'Company',
      //     to: { name: 'create-company' },
      //   },
      //   {
      //     text: 'View Company',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: '/division-management',
    name: 'division-management',
    component: () => import('@/component/division/division-main.vue'),
    // component: () => import('@/component/division/division-modal.vue'),
    meta: {
      pageTitle: 'Division Management',
      // breadcrumb: [
      //   {
      //     text: 'Company',
      //     to: { name: 'create-company' },
      //   },
      //   {
      //     text: 'View Company',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: '/score-management',
    name: 'score-management',
    component: () => import('@/views/pages/score-management.vue'),
    meta: {
      pageTitle: 'Score Management',
      // breadcrumb: [
      //   {
      //     text: 'Company',
      //     to: { name: 'create-company' },
      //   },
      //   {
      //     text: 'View Company',
      //     active: true,
      //   },
      // ],
    },
  },
  // History Performance Global
  {
    path: '/history-performance',
    name: 'history-performance',
    component: () => import('@/views/pages/summary/history-performance.vue'),
    meta: {
      pageTitle: 'History Approved Performance',
      // activePage: 'performance-cycle',
      breadcrumb: [
        {
          text: 'Performance Management Summary',
          to: { name: 'summary-report' },
        },
        {
          text: 'History Approved Performance',
          active: true,
        },
      ],
    },
  },
  // History performance for Top Management
  {
    path: '/history-performance-tm',
    name: 'history-performance-tm',
    component: () => import('@/views/pages/summary/history-performance-top-management.vue'),
    meta: {
      pageTitle: 'History Approved Performance',
      // activePage: 'performance-cycle',
      breadcrumb: [
        {
          text: 'Performance Management Summary',
          to: { name: 'summary-report-tm' },
        },
        {
          text: 'History Approved Performance',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/competency-management',
  //   name: 'competency-management',
  //   component: () => import('@/component/competency/get_behavioural.vue'),
  //   meta: {
  //     pageTitle: 'Competency Management',
  //   },
  // },
  {
    path: '/View-Competency/:id',
    name: 'View-Competency',
    component: () => import('@/component/competency/get_sub_behavioural.vue'),
    meta: {
      pageTitle: 'Competency Management',
      breadcrumb: [
        {
          text: 'Competency Management',
          to: { name: 'competency-management' },
        },
        {
          text: 'View Sub Behavioural',
          active: true,
        },
      ],
    },
  },
  {
    path: '/training',
    name: 'training',
    component: () => import('@/pages/training_record.vue'),
    meta: {
      pageTitle: 'Training Record',
    },
  },
  {
    path: '/section',
    name: 'section',
    component: () => import('@/views/pages/section/section_main.vue'),
    meta: {
      pageTitle: 'Section Management',
    },
  },
  {
    path: '/section-details',
    name: 'Section-Details',
    component: () => import('@/views/pages/section/employee_assigned.vue'),
    meta: {
      pageTitle: 'Section Details',
      breadcrumb: [
        {
          text: 'Section Management',
          to: { name: 'section' },
        },
        {
          text: 'Section Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reference',
    name: 'reference',
    component: () => import('@/views/pages/reference/reference-main.vue'),
    meta: {
      pageTitle: 'Reference',
    },
  },
  {
    path: '/section-summary-report',
    name: 'section-summary-report',
    component: () => import('@/views/pages/summary/summary-report-main.vue'),
    meta: {
      pageTitle: 'Section Performance Management Summary',
    },
  },
  {
    path: '/section-history-performance',
    name: 'section-history-performance',
    component: () => import('@/views/pages/section/section-history-performance.vue'),
    meta: {
      pageTitle: 'Section History Approved Performance',
      // activePage: 'performance-cycle',
      breadcrumb: [
        {
          text: 'Section Performance Management Summary',
          to: { name: 'section-summary-report' },
        },
        {
          text: 'Section History Approved Performance',
          active: true,
        },
      ],
    },
  },
  {
    path: '/View-Reference-Sub-Behav/:id',
    name: 'View-Reference-Sub-Behav',
    component: () => import('@/views/pages/reference/reference-get-sub-behavioural.vue'),
    meta: {
      pageTitle: 'Reference',
      breadcrumb: [
        {
          text: 'Reference',
          to: { name: 'reference' },
        },
        {
          text: 'View Sub Behavioural',
          active: true,
        },
      ],
    },
  },
  {
    path: '/division-report',
    name: 'division-report',
    component: () => import('@/views/pages/summary/summary-report-main.vue'),
    meta: {
      pageTitle: 'Division Performance Management Summary',
    },
  },
  {
    path: '/division-history-performance',
    name: 'division-history-performance',
    component: () => import('@/views/pages/summary/history-performance.vue'),
    meta: {
      pageTitle: 'Division History Approved Performance',
      // activePage: 'performance-cycle',
      breadcrumb: [
        {
          text: 'Division Performance Management Summary',
          to: { name: 'division-report' },
        },
        {
          text: 'Division History Approved Performance',
          active: true,
        },
      ],
    },
  },
  {
    path: '/weightage',
    name: 'weightage',
    component: () => import('@/views/pages/weightage/weightage_main.vue'),
    meta: {
      pageTitle: 'Weightage Management',
    },
  },
  // new added in progress performance details
  // Performance Details Individual for SHR, HR, HOD, Section, Division
  // Performance Management Summary
  {
    path: '/performance-details-individual/:id/:performance_id/:tabIdx?',
    name: 'performance-details-individual',
    component: () => import('@/views/pages/summary/performance-details-individual-global.vue'),
    meta: {
      pageTitle: 'Individual Performance',
      activePage: 'summary-report',
      breadcrumb: [
        {
          text: 'Performance Details',
          to: { name: 'summary-report', params: { tabIdx: 1 } },
        },
        {
          text: 'Individual Performance',
          active: true,
        },
      ],
    },
  },
  // Division Report
  {
    path: '/performance-details-individual-division/:id/:performance_id/:tabIdx?',
    name: 'performance-details-individual-division',
    component: () => import('@/views/pages/summary/performance-details-individual-global.vue'),
    meta: {
      pageTitle: 'Division Performance Management Summary',
      activePage: 'division-report',
      breadcrumb: [
        {
          text: 'Performance Details',
          to: { name: 'division-report' },
        },
        {
          text: 'Individual Performance',
          active: true,
        },
      ],
    },
  },
  // Section Report
  {
    path: '/performance-details-individual-section/:id/:performance_id/:tabIdx?',
    name: 'performance-details-individual-section',
    component: () => import('@/views/pages/summary/performance-details-individual-global.vue'),
    meta: {
      pageTitle: 'Section Performance Management Summary',
      activePage: 'section-summary-report',
      breadcrumb: [
        {
          text: 'Performance Details',
          to: { name: 'section-summary-report' },
        },
        {
          text: 'Individual Performance',
          active: true,
        },
      ],
    },
  },
  // Performance Details Individual for SHR, HR, HOD, Section, Division End
  {
    path: '/testingfina',
    name: 'testingfina',
    component: () => import('@/views/pages/performance-statistics/edit_grade.vue'),
    meta: {
      pageTitle: 'testing',
    },
  },
]
