<template>
  <div class="d-flex align-items-center justify-content-between px-1">
    <div

      class="col-4 d-flex align-items-center justify-content-start pl-1"
      style="width: 50px;"
    >
      <div>
        <b-button
          v-b-tooltip.top
          tabindex="0"
          :title="base.fontSize <= 7 ?'Min font size reach': ''"
          class="d-flex justify-content-center align-items-center"
          style="background-color: transparent !important; padding: 0px;
               width: 35px;
               height: 35px;
               border-end-end-radius: 0px;
               border-top-right-radius: 0px;
               border: solid 2px white !important;
               border-right: none;
              "
          @click="changeFont('minus')"
        >
          <feather-icon
            icon="MinusIcon"
            size="15"
            stroke="white"
            height="10px"
          />
        </b-button>
      </div>
      <span
        v-b-tooltip.top
        tabindex="0"
        title="Reset Font Size"
        class="d-flex justify-content-center align-items-center saj-title pointer"
        style="color: white; font-size: 16px; width: auto; padding-right: 10px; padding-left: 10px; border: 2px solid white; height: 35px;"
        @click="resetFont()"
      >{{ ((base.fontSize / 16)*100).toFixed(0) }}%</span>
      <div>
        <b-button
          v-b-tooltip.top
          tabindex="0"
          :title="base.fontSize > 25 ? 'Max font size reach' : ''"
          class="d-flex justify-content-center align-items-center"
          style="background-color: transparent !important; padding: 0px;
               width: 35px ;
               height: 35px;
               border-top-left-radius: 0px;
               border-bottom-left-radius: 0px;
               border: solid 2px white !important;
               border-left: none !important;
               "
          @click="changeFont('plus')"
        >
          <feather-icon
            icon="PlusIcon"
            size="15"
            stroke="white"
          />
        </b-button>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div
        class="mr-1"
        style="text-align:center"
      >
        <p
          class="saj-text m-0"
          style="color: white; font-size: 0.8rem !important;"
        >
          {{ userDetails.full_name }}
        </p>
        <p
          class="saj-text m-0"
          style="color: white; font-size: 0.8rem !important;"
        >
          {{ sub }}
        </p>
      </div>
      <div class="ml-1">
        <Localization
          outline="outline-light"
          variant="outline-light"
        />
      </div>
      <notification-dropdown class="d-flex align-items-center justify-content-center" />
      <user-dropdown class="d-flex align-items-center justify-content-center" />
    </div>

  </div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
// import { Base } from '@/menu/event-controller'
import { mapActions, mapGetters } from 'vuex'
import Localization from '@/views/base/other/localization.vue'
import axios from "axios"
import store from '@/store/index'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    NotificationDropdown,
    UserDropdown,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    Localization,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data(){
    return {
      defaultFont: 16,
      sub: null,
    }
  },
  computed: {
    ...mapGetters(['base', 'userDetails']),
  },
  mounted(){
    // console.log(this.userDetails)
    if (store.getters.sajRanhillToken !== null){
      this.getSubsidiary()
    }
  },
  methods: {
    ...mapActions(['setFontSize']),
    changeFont(val) {
      let tempFont = this.base.fontSize
      if (val === 'minus' && this.base.fontSize > 7){
        tempFont -= 2
      } else if (val === 'plus' && this.base.fontSize < 25){
        tempFont += 2
      }
      // Base.$emit('change-font', this.tempFont)
      this.setFontSize(tempFont)
    },
    resetFont(){
      // Base.$emit('change-font', this.defaultFont)
      this.setFontSize(this.defaultFont)
    },
    getSubsidiary(){
      const params = new URLSearchParams()
      params.append('id', this.userDetails.business_unit)

      axios.get(`${this.$baseUrl}/subsidiary/getById`, { params }).then(response => {
        this.sub = response.data.data[0].subsidiary_name
        // console.log(this.sub)
      })
    },
  },
}
</script>
<style scoped>
/* .local :hover{
background-color: transparent !important;
} */
</style>
