<template>
  <div
    class="tooltip-bg"
    style="padding: 2px; height: auto; width: 200px;"
  >
    <span class="tooltip-title">{{ title }}</span>
  </div>
</template>
<style>
.tooltip-bg {
  background-color: rgba(255, 0, 0, 0.35);
  border-radius: 5px;
  margin-top: 10px;
}
.tooltip-title {
    font-size: 0.95rem;
    color: #FFFFFF;
}
</style>
<script>
export default {
  components: {
  },
  directives: {
  },
  props: {
    title: {
      type: String,
      default: 'Title',
    },
  },

  data(){
    return {

    }
  },
  mounted(){

  },
  methods: {

  },
}
</script>
