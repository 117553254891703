<template>
  <div class="toastification">

    <div class="d-flex align-items-center">
      <!-- <b-avatar
        :variant="iconColor"
        size="1.8rem"
        class="mr-75 flex-shrink-0"
      >
        <feather-icon
          :icon="icon"
          :class="`text-${variant}`"
          size="15"
        />
      </b-avatar> -->
      <div
        class="d-flex align-items-center justify-content-center "
        style="padding: 5px;"
        :style="`background-color: ${iconBg}; border-radius: ${radiusBg}; `"
      >
        <!-- {{ iconBg }} -->
        <img
          v-if="img"
          :src="img"
          alt="no picture"
          width="24"
          height="24"
        >
        <feather-icon
          v-if="icon"
          :icon="icon"
          :style="`color: ${iconColor};`"
          width="24"
          height="24"
        />
        <i
          v-if="fafont"
          :class="`${fafont}`"
          class="d-flex justify-content-center align-items-center"
          :style="`color: ${iconColor}; height: 24px; width:24px`"
        />
      </div>
      <div class="d-flex flex-grow-1 ml-1">
        <div class="d-flex flex-column">
          <span
            v-if="title"
            class="mb-0 font-weight-bolder toastification-title"
            :style="[
              { color: `${titleColor}`, 'font-size': '0.8rem' }
            ]"
            v-text="title"
          />
          <small
            v-if="text"
            class="d-inline-block"
            :style="[
              { color: `${titleColor}`, 'font-size': '0.7rem' }
            ]"
            v-text="text"
          />
          <span
            v-if="hyperLink"
            style="font-size: 0.85rem; font-weight:400; margin-top:3px; cursor: pointer;"
            :style="`color: ${titleColor}`"
            class="d-flex align-sitems-center"
            @click="$emit('clickTicket')"
          >
            {{ hyperLink }}
            <feather-icon
              style="margin-top: 4px;"
              icon="ChevronRightIcon"
            />
          </span>
        </div>
        <span
          class="cursor-pointer toastification-close-icon ml-auto "
          @click="$emit('close-toast')"
        >
          <feather-icon
            v-if="!hideClose"
            icon="XIcon"
            :class="`text-${variant}`"
          />
        </span>
      </div>
    </div>

  </div>
</template>

<script>
// import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    // BAvatar,
  },
  props: {
    variant: {
      type: String,
      default: 'light',
    },
    titleColor: {
      type: String,
      default: '#00F0FF',
    },
    iconColor: {
      type: String,
      default: 'white',
    },
    iconBg: {
      type: String,
      default: '#06F7A1',
    },
    radiusBg: {
      type: String,
      default: '10px',
    },
    icon: {
      type: String,
      default: '',
    },
    fafont: {
      type: String,
      default: '',
    },
    img: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    hideClose: {
      type: Boolean,
      default: true,
    },
    hyperLink: {
      type: String,
      default: '',
    },
  },
  mounted() {

  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  // line-height: 26px;
  line-height: 20px;
}

.toastification-title {
  color: inherit;
}
</style>
