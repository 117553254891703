<template>
  <div style="background-color: #FF0000;">
    horizontal nav menu
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {

  },
  data(){
    return {

    }
  },
  mounted(){

  },
  methods: {

  },
}
</script>
