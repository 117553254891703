<template>

  <div
    class="p-1 modalScroll "
    style="width: auto; height: 55vh;"
  >
    <div
      class="mb-1 saj-title d-flex justify-content-center mt-2"
      style=""
    >
      {{ $t ("Update Password") }}
      <!-- <br>email ni:{{ email }} -->
    </div>
    <validation-observer ref="validatePassword">
      <div class="d-flex justify-content-center">

        <div class="form-group col-lg-8 col-sm-8 col-md-8 mb-2 mt-3 ">
          <b-form-group
            class="saj-text"
            :label="$t('Old Password')"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Old Password')"
              rules="required"
            >
              <input
                id="new_password"
                v-model="newPassword"
                :type="[showPassword ? 'text' : 'password']"
                class="form-control form-control-lg"
                :placeholder="$t('Old Password')"
              >
              <i
                class="fa field-icon"
                :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']"
                aria-hidden="true"
                @click="showPassword = !showPassword"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <div class="form-group col-lg-8 col-sm-8 col-md-8 mb-3 ">
          <b-form-group
            class="saj-text"
            :label="$t('New Password')"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('New Password')"
              rules="required"
            >
              <input
                id="confirm_password"
                v-model="retypePassword"
                :type="[confirmPassword ? 'text' : 'password']"
                class="form-control form-control-lg"
                :placeholder="$t('Re-type New Password')"
              >
              <i
                class="fa field-icon"
                :class="[confirmPassword ? 'fa-eye' : 'fa-eye-slash']"
                aria-hidden="true"
                @click="confirmPassword = !confirmPassword"
              />
              <!-- <div
          id="message3"
          style="color: red; font-size: 11px;"
        /> -->
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <div
        class="d-flex justify-content-end"
        style=""
      >
        <button
          class="btn btn-close m-1 saj-button"
          aria-label="Close modal"
          style="
                  color: white;
                  background: #ff0000;
                "
          @click="close()"
        >
          {{ $t('Cancel') }}
        </button>
        <button
          class="btn m-1 saj-button"
          aria-label="submit modal"
          style="
                  color: white;
                  background: #0b103c !important;
                  border-color: #0b103c !important;
                "
          @click="changePassword()"
        >
          {{ $t ("Update Password") }}
        </button>
      </div>
    </validation-observer></div>

</template>
<style scoped>

    .split {
      height: 100%;
      width:50%;
      position: fixed;
      z-index: 1;
      top: 0;
      overflow-x: hidden;
      padding-top: 20px;
    }

    .left {
   background: url('~@/assets/images/background/login.png');
      background-repeat: no-repeat;
      background-size: auto 100%;
      /* background-image: url('~@/assets/images/background/login.png');
      height: 100%; */
      background-position: center;
    }
    .right {
      right: 0;
      background-color: white;
    }

    .centered {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 100px;
        text-align: center;
    }

    .pass_show{position: relative}

    .pass_show .ptxt {
      position: absolute;
      top: 50%;
      right: 10px;
      z-index: 1;
      margin-top: -10px;
      cursor: pointer;
      transition: .3s ease all;
    }

    .pass_show .ptxt:hover{color: #333333;}

    .field-icon {
      float: right;
      margin-left: -25px;
      margin-right: 10px;
      margin-top: -30px;
      position: relative;
      z-index: 2;
    }

    .centered img {
      width: 150px;
      border-radius: 50%;
    }
    .screensize{
      width: 48%;
    }

    /* Split screen */
    @media screen and (max-width: 1100px) {
        .overridesplitright{
          width: 100% !important;
        }

        .overridesplitleft{
          display: none !important;
        }
    }
    /* End Split screen */
    @media screen and (max-width: 2000px) {
        .screensize{
          width: 48%;
        }

        .code{
          font-size:18px;
          height: 72px;
          margin-left: 3%;
          width: 10px;
        }
    }
    @media screen and (max-width: 1700px) {
      .screensize{
        width: 56%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 1400px) {
      .screensize{
        width: 66%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 1200px) {
      .screensize{
        width: 76%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 1100px) {
      .screensize{
        width: 76%;
      }

      .code{
        font-size:18px;
        height: 72px;
        margin-left: 3%;
        width: 10px;
      }
    }
    @media screen and (max-width: 400px) {
      .screensize{
        width: 86%;
      }

      .code{
        font-size:12px;
        height: 62px;
        margin-left: 2%;
        width: 10px;
      }
    }
    @media screen and (max-width: 300px) {
      .screensize{
        width: 90%;
      }

      .code{
        font-size:10px;
        height: 52px;
        margin-left: 2%;
        width: 10px;
      }
    }

</style>
<script>
import {
  BFormGroup,
} from "bootstrap-vue"

import SAJToast from '@/component/saj-toastification.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      required,
      showPassword: false,
      confirmPassword: false,
      newPassword: "",
      retypePassword: "",
      email: "",
    }
  },

  mounted() {
    this.getDetails()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    getDetails() {
      const params = new FormData()

      this.$axios
        .get(`${this.$baseUrl}/users/current_user`, params)
        .then(response => {
          if (response.data.success === true) {
            this.email = response.data.data.email
            // console.log("CURRENT USER", this.email)
          } else {
            // this.$errorConfirmation()
            this.errorConfirmation("danger")
          }
        })
    },
    // match() {
    //   document.getElementById("new_password").style.borderColor = ""
    //   document.getElementById("confirm_password").style.borderColor = ""
    //   document.getElementById('message3').innerHTML = ""

    //   if (document.getElementById('new_password').value === document.getElementById('confirm_password').value) {
    //     document.getElementById('message3').style.color = 'green'
    //     document.getElementById('message3').innerHTML = 'matching'
    //   } else if (document.getElementById('new_password').value !== document.getElementById('confirm_password').value){
    //     document.getElementById('message3').style.color = 'red'
    //     document.getElementById('message3').innerHTML = 'not matching'
    //     document.getElementById("new_password").style.borderColor = "red"
    //     document.getElementById("confirm_password").style.borderColor = "red"
    //   } else {
    //     document.getElementById('message3').innerHTML = ""
    //     document.getElementById("new_password").style.borderColor = ""
    //     document.getElementById("confirm_password").style.borderColor = ""
    //   }
    // },
    changePassword(){
      this.$refs.validatePassword.validate().then(success => {
        if (success){
          const data = new FormData()
          data.append('email', this.email)
          data.append('old_password', this.newPassword)
          data.append('new_password', this.retypePassword)
          // data.forEach((value, key) => {
          //   console.log(`${key} ${value}`)
          // })
          this.$axios.post(`${this.$baseUrl}/password/change`, data).then(() => {
            this.$emit('close')
            setTimeout(() => {
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: `${this.$t('Password successfully updated')}.`,
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    titleColor: '#000',
                  },
                },
                {
                  position: "top-right",
                  type: 'info',
                },
              )
            }, 1000)
          }).catch(() => {
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('Please enter your old and new password')}.`,
                  icon: "AlertCircleIcon",
                  iconBg: '#53b7db',
                  titleColor: '#000',
                },
              },
              {
                position: "top-right",
                type: 'error',
              },
            )
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

</style>
